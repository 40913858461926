<template>
  <b-overlay :show="showOverlay">
    <div>
    <b-modal v-model="modalShow" @ok="deleteRace()" @cancel="raceIdToBeDeleted=null">
      Etes vous sûr de vouloir supprimer cette course ?
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="cancel()">
          Annuler
        </b-button>
        <b-button size="sm" variant="danger" @click="ok()">
          Supprimer
        </b-button>
      </template>
    </b-modal>
    </div>
    <b-table striped borderless small :items="races" :fields="fields">
      <template v-slot:cell(date)="data">
        {{ $DateTime.fromISO(data.item.date).toFormat('yyyy-MM-dd hh:mm')}}
      </template>
       <template v-slot:cell(edit)="data">
        <router-link :to="'/race/pending/edit/'+data.item._id">
          <b-icon-pencil-fill variant="warning"/>
        </router-link>
      </template>
      <template v-slot:cell(delete)="data">
        <b-icon-x-circle-fill variant="danger" @click="confirmDeleteRace(data.item._id)"/>
      </template>
      <template v-slot:cell(raceDate)="data">
        {{ $DateTime.fromISO(data.item.raceDate).toFormat('yyyy-MM-dd')}}
      </template>
    </b-table>
  </b-overlay>
</template>

<script>

export default {
  name: 'RacePendingAll',
  data() {
    return {
      races: [],
      fields: [
        { key: 'date', label: 'Date d\'envoi' },
        { key: 'filename', label: 'Nom de fichier' },
        { key: 'username', label: 'Utilisateur' },
        { key: 'raceName', label: 'Intitulé' },
        { key: 'raceDate', label: 'Date de la course' },
        { key: 'edit', label: '' },
        { key: 'delete', label: '' },
      ],
      showOverlay: false,
      modalShow: false,
      raceIdToBeDeleted: null,
    };
  },
  async created() {
    await this.getPendingRaces();
  },
  components: {
  },
  methods: {
    async getPendingRaces() {
      this.showOverlay = true;
      const response = await this.axios.get('/race/pending/all');
      if (response && Array.isArray(response.data)) {
        this.races = response.data;
      }
      this.showOverlay = false;
    },
    confirmDeleteRace(id) {
      this.raceIdToBeDeleted = id;
      this.modalShow = true;
    },
    async deleteRace() {
      this.showOverlay = true;
      await this.axios.delete(`/race/pending/${this.raceIdToBeDeleted}`);
      this.showOverlay = false;
      this.raceIdToBeDeleted = null;
      await this.getPendingRaces();
    },
  },
};
</script>
